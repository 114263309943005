<template>
  <div>
    <Drawer :mask-closable="false"
            :closable="false"
            :mask="false"
            :transfer="false"
            inner
            scrollables
            :width="width"
            :value="visible">
      <div class="main">
        <div class="topbar j-between">
          <div class="flex a-center">
            <div class="topbar-btn m-r-40"
                 @click.stop="onClickClose">
              <Icon custom="i-icon icon-back"
                    size="32"
                    class="m-r-8"></Icon>
              返回
            </div>
          </div>
        </div>
        <div class="detail">
          <div class="form">
            <ul class="form-ul">
              <li class="form-li">
                <div class="form-item">
                  <div class="form-key">
                    <span style="color:#D0021B">*</span>
                    <span>商品名称:</span>
                  </div>
                  <div class="form-value"
                       style="width:240px">{{goodsName}}</div>
                </div>
                <div class="form-item">
                  <div class="form-key">
                    <span style="color:#D0021B">*</span>
                    <span>农药名称:</span>
                  </div>
                  <div class="form-value"
                       style="width:240px">{{pestName}}</div>
                </div>
                <div class="form-item">
                  <div class="form-key">
                    <span style="color:#D0021B">*</span>
                    <span>持证企业:</span>
                  </div>
                  <div class="form-value"
                       style="width:240px">{{entName}}</div>
                </div>
                <div class="form-item">
                  <div class="form-key">
                    <span style="color:#D0021B">*</span>
                    <span>生产企业:</span>
                  </div>
                  <div class="form-value"
                       style="width:240px">{{prodEntName}}</div>
                </div>
              </li>
              <li class="form-li">
                <div class="form-item">
                  <div class="form-key">
                    <span style="color:#D0021B">*</span>
                    <span>生产类型:</span>
                  </div>
                  <div class="form-value"
                       style="width:240px">{{prodType===1?'持证人生产':prodType===2?'委托生产':'委托分装'}}</div>
                </div>
                <div class="form-item">
                  <div class="form-key">
                    <span style="color:#D0021B">*</span>
                    <span>商品规格:</span>
                  </div>
                  <div class="form-value"
                       style="width:240px">{{goodsSpec}}</div>
                </div>
                <div class="form-item">
                  <div class="form-key">
                    <span style="color:#D0021B">*</span>
                    <span>生产批次:</span>
                  </div>
                  <div class="form-value"
                       style="width:240px">{{prodBatch===1?'见包装喷码':'激活后显示日期'}}</div>
                </div>
                <div class="form-item">
                  <div class="form-key">
                    <span style="color:#D0021B">*</span>
                    <span>生产日期:</span>
                  </div>
                  <div class="form-value"
                       style="width:240px">{{prodData===1?'见包装喷码':'激活后显示日期'}}</div>
                </div>
                <div class="form-item">
                  <div class="form-key">
                    <span style="color:#D0021B">*</span>
                    <span>开码数量:</span>
                  </div>
                  <div class="form-value"
                       style="width:240px">{{yardsNumber}}</div>
                </div>
                <Button type="primary"
                        class="form-btn"
                        @click.stop="downloadYardsData">下载数据包</Button>
              </li>
            </ul>
          </div>
        </div>
        <div class="list">
          <div class="table">
            <Table :height="tableHeight"
                   :columns="columns"
                   :data="data">
              <template slot-scope="{ row }"
                        slot="operation">
                <Button @click.stop="onClickDetail(row)"
                        type="text"
                        style="color: #0652DD;width:40px;text-align: center;padding: 0">预览</Button>
              </template>
            </Table>
            <Page class="m-t-10 m-l-10"
                  :total="totalPage"
                  :current="pageNum"
                  :page-size="pageSize"
                  show-total
                  show-sizer
                  show-elevator
                  @on-change="onPageChange"
                  @on-page-size-change="onPageSizeChange" />
          </div>
        </div>
      </div>
    </Drawer>
    <Modal v-model="codeVisible"
           title=""
           footer-hide
           @on-visible-change="clearCodeUrl"
           width="360">
      <div class="codeModal">
        <vue-qr :text="codeUrl"
                :margin="0"
                :size="200"></vue-qr>
        <p>扫一扫预览商品信息</p>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getOpenYardsDetail, getProductTaskList } from '../../services/yardsapi';
import { dowloadFile } from '../../utils/ossdowload';
import vueQr from 'vue-qr'
export default {
  components: { vueQr },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean,
    width: Number,
    ruleId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      goodsName: '',
      pestName: '',
      entName: '',
      prodEntName: '',
      prodType: 1,
      goodsSpec: '',
      prodBatch: 1,
      prodData: 1,
      yardsNumber: 0,
      yardDataUrl: '',
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      columns: [
        {
          title: '序列号',
          width: 180,
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '生码编号',
          key: 'code',
          minWidth: 200
        },
        {
          title: '二维码数据',
          key: 'qrCode',
          minWidth: 300
        },
        {
          title: ' ',
          slot: 'operation',
          width: 90,
          align: 'right'
        }
      ],
      data: [],
      codeVisible: false,
      codeUrl: ''
    }
  },
  watch: {
    visible (newVal) {
      if (!newVal) {
        this.$emit('update:ruleId', 0)
      }
    },
    ruleId (newVal) {
      if (newVal && this.visible) {
        this.getOpenYardsDetail()
        this.getProductTaskList()
      } else {
        this.$emit('update:ruleId', 0)
      }
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.tableHeight = window.innerHeight - 480
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 480
      }
    },
    // 点击取消
    onClickClose () {
      this.$emit('change', false)
    },
    // 获取开码详情
    getOpenYardsDetail () {
      getOpenYardsDetail(1, { ruleId: this.ruleId }).then(res => {
        if (res.code === 0) {
          let data = res.data
          this.goodsName = data.traceGoodsName
          this.pestName = data.genericName
          this.entName = data.holderList.length > 0 ? data.holderList[0].entName : ''
          this.prodEntName = data.productEntName
          this.prodType = data.ruleProduct.produceTypeCode
          this.goodsSpec = data.traceSpecDesc
          this.prodBatch = data.produceSerialType
          this.prodData = data.produceDateType
          this.yardsNumber = data.genQty
          this.yardDataUrl = data.dataPackageUrl
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 页数改变
    onPageChange (event) {
      this.selectArr = []
      this.pageNum = event
      this.getProductTaskList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.selectArr = []
      this.pageSize = event
      this.getProductTaskList()
    },
    // 获取生产任务列表
    getProductTaskList () {
      let params = {
        ruleId: this.ruleId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      getProductTaskList(params).then(res => {
        this.totalPage = res.data.total
        this.data = res.data.list
      })
    },
    // 点击预览
    onClickDetail (row) {
      this.codeUrl = row.qrCode
      this.codeVisible = true
    },
    clearCodeUrl (status) {
      if (!status) {
        this.codeUrl = ''
      }
    },
    // 下载数据包
    downloadYardsData () {
      // http://image.test.laodao.so/file/tradeapp/qrcode/trace/e2638f90-c7f5-3794-9322-4c43c9ecae98.txt
      let dowloadUrl = dowloadFile(this.yardDataUrl)
      // 创建a标签
      const link = document.createElement('a')
      // href链接
      link.setAttribute('target', '_blank')
      link.setAttribute('href', dowloadUrl)
      // 自执行点击事件
      link.click()
    },
  },
}
</script>

<style lang='scss' scoped>
.main {
  height: 100%;
  background-color: #f9f9ff;
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px;
    .topbar-btn {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      color: #666;
      &:hover {
        color: #0652dd;
      }
    }
    ::v-deep {
      .ivu-select-selection,
      .ivu-input {
        background-color: transparent;
        border-color: #9c9ccd;
        border-radius: 18px;
      }
      .ivu-select-selection span {
        padding-left: 12px;
      }
      .ivu-input {
        padding: 4px 12px;
      }
    }
  }
  .title {
    padding: 0 16px;
    border-bottom: 1px solid #d2d0e7;
    display: flex;
    span {
      line-height: 52px;
      color: #0652dd;
      border-bottom: 1px solid #0652dd;
    }
  }
  .list {
    background-color: #fff;
    margin: 20px;
    .table {
      padding: 10px 20px 20px;
    }
    ::v-deep {
      .ivu-table-cell {
        padding: 0 8px;
      }
    }
  }
}
.detail {
  background-color: #fff;
  margin: 20px;
  .form {
    .form-ul {
      padding: 10px 20px;
      .form-li {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .form-item {
          display: flex;
          align-items: center;
          margin-right: 70px;
          padding: 15px 0;
          .form-key {
            font-size: 16px;
            flex-shrink: 0;
            margin-right: 10px;
            span:first-child {
              width: 8px;
              display: inline-block;
            }
          }
          .form-value {
            min-height: 32px;
            font-size: 16px;
            line-height: 32px;
            padding: 0 10px;
            border-bottom: 1px solid #dcdbec;
          }
        }
        .form-btn {
          margin-right: 25px;
        }
        ::v-deep .ivu-btn {
          line-height: 2;
        }
      }
    }
  }
}
::v-deep .ivu-select-dropdown-list {
  max-height: 300px;
}
::v-deep .ivu-drawer {
  top: 64px;
  .ivu-drawer-body {
    padding: 0;
  }
}
.codeModal {
  padding: 60px;
  text-align: center;
  p {
    font-size: 16px;
    color: #666;
    margin-top: 17px;
  }
}
</style>