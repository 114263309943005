import {
  del,
  get,
  patch,
  post,
  put
} from './http'

/**
 * 获取开码记录列表
 */
export const getYardsList = (params) => get('/tradeapp/trace/rule/ent/list', params)

/**
 * 生成二维码
 */
export const openYards = (params) => post('/tradeapp/trace/code', params)

/**
 * 获取生产任务信息
 */
export const getProductTaskInfo = (id) => get('/tradeapp/trace/rule/ent/' + id)

/**
 * 获取生产任务列表
 */
export const getProductTaskList = (params) => get('/tradeapp/trace/code/ent/rule/list', params)

/**
 * 获取追溯码详情
 */
export const getYardsDetail = (id, params) => get('/tradeapp/trace/code/user/' + id, params)

/**
 * 获取开码详情
 */
export const getOpenYardsDetail = (type, params) => get('/tradeapp/trace/rule/ent/rule/' + type, params)

/**
 * 获取导入追溯码列表
 */
export const getImportYardsList = (params) => get('/tradeapp/trace/code/ent/list', params)

/**
 * 批量删除开码记录
 */
export const deleteYards = (params) => del('/tradeapp/trace/rule/ent', params)