<template>
  <div class="main"
       ref="main">
    <div class="topbar">
      <div class="flex a-center"
           v-show="selectArrSize===0">
        <Select v-model="type"
                placeholder="农药类别"
                label-in-value
                @on-change="onChangeType"
                style="width:120px;margin-right:24px">
          <Option v-for="item in typeArr"
                  :value="item.id"
                  :label="item.name"
                  :key="item.id">{{ item.name }}</Option>
        </Select>
        <DatePicker :value="startDate"
                    placeholder="开始时间"
                    @on-change="onChangeStartDate"
                    style="width:160px"
                    type="date"></DatePicker>
        <span style="color:#9D9DCD;margin:0 8px">—</span>
        <DatePicker :value="endDate"
                    placeholder="结束时间"
                    @on-change="onChangeEndDate"
                    style="width:160px;margin-right:24px"
                    type="date"></DatePicker>
        <Input v-model="searchValue"
               placeholder="商品名称"
               search
               @on-search="onSearch"
               style="width:280px" />
      </div>
      <div class="flex a-center"
           v-show="selectArrSize>0">
        <div class="topbar-btn"
             @click.stop="batchDelete">
          <Icon custom="i-icon icon-delete"
                size="32"
                class="m-r-8"></Icon>
          删除
        </div>
      </div>
    </div>
    <div class="list">
      <div class="table">
        <Table ref="table"
               :height="tableHeight"
               :columns="columns"
               :data="data"
               @on-select="onSelect"
               @on-select-cancel="onSelectCancel"
               @on-select-all="onSelectAll"
               @on-select-all-cancel="onSelectAll">
          <template slot-scope="{ row }"
                    slot="operation">
            <Button @click.stop="onClickDetail(row)"
                    type="text"
                    style="color: #0652DD;width:40px;text-align: center;padding: 0">详情</Button>
            <Button @click.stop="downloadYardsData(row)"
                    type="text"
                    style="color: #0652DD;width:80px;text-align: center;padding: 0">下载数据包</Button>
            <Button @click.stop="onClickDelete(row)"
                    type="text"
                    style="color: #0652DD;width:40px;text-align: center;padding: 0">删除</Button>
          </template>
        </Table>
        <Page class="m-t-10 m-l-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
      </div>
    </div>
    <YardsDetailDrawer :width="drawerWidth"
                       v-model="detailVisible"
                       :ruleId.sync="detailRuleId"></YardsDetailDrawer>
  </div>
</template>

<script>
import { getLicenseTypeList } from '../../../services/goodsapi';
import { getYardsList, deleteYards } from '../../../services/yardsapi';
import { dowloadFile } from '../../../utils/ossdowload';
import { getNowFormatDay } from '../../../utils/utils';
import YardsDetailDrawer from '../../../components/product/YardsDetailDrawer';
export default {
  components: { YardsDetailDrawer },
  data () {
    return {
      type: 0,
      typeName: '',
      typeArr: [
        {
          id: 0,
          name: '全部类别'
        }
      ],
      startDate: '',
      endDate: '',
      searchValue: '',
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      columns: [
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '序号',
          width: 80,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '商品名称',
          key: 'goodsName',
          tooltip: true,
          width: 160
        },
        {
          title: '农药名称',
          key: 'mainIngredient',
          tooltip: true,
          minWidth: 160
        },
        {
          title: '规格',
          key: 'specDesc',
          tooltip: true,
          minWidth: 160,
        },
        {
          title: '持证企业',
          key: 'entName',
          tooltip: true,
          minWidth: 260
        },
        {
          title: '开码数量',
          key: 'genQty',
          tooltip: true,
          width: 160,
          align: 'center'
        },
        {
          title: '开码日期',
          key: 'createTime',
          tooltip: true,
          width: 160,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 180,
          align: 'right'
        }
      ],
      data: [],
      selectArr: new Set(),
      selectArrSize: 0,
      drawerWidth: 0,
      /* 商品详情 */
      detailVisible: false,
      detailRuleId: 0,
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.tableHeight = window.innerHeight - 250
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 250
      }
      this.drawerWidth = this.$refs.main.offsetWidth
      this.endDate = getNowFormatDay()
      this.getYardsList()
      this.getLicenseTypeList()
    },
    // 获取农药类别
    getLicenseTypeList () {
      getLicenseTypeList({ type: 1 }).then(res => {
        this.typeArr = this.typeArr.concat(res.data)
      })
    },
    onChangeType (obj) {
      this.typeName = obj.label
      this.onSearch()
    },
    onChangeStartDate (date) {
      this.startDate = date
      this.onSearch()
    },
    onChangeEndDate (date) {
      this.endDate = date
      this.onSearch()
    },
    onSelectAll (selection) {
      if (selection.length === 0) {
        let data = this.$refs.table.data
        data.forEach((item) => {
          if (this.selectArr.has(item.id)) {
            this.selectArr.delete(item.id)
          }
        })
      } else {
        selection.forEach(item => {
          this.selectArr.add(item.id)
        })
      }
      this.selectArrSize = this.selectArr.size
    },
    //  选中某一行
    onSelect (selection, row) {
      this.selectArr.add(row.id)
      this.selectArrSize = this.selectArr.size
    },
    //  取消某一行
    onSelectCancel (selection, row) {
      this.selectArr.delete(row.id)
      this.selectArrSize = this.selectArr.size
    },
    setChecked () {
      var objData = this.$refs.table.$refs.tbody.objData;
      for (let index in objData) {
        if (this.selectArr.has(objData[index].id)) {
          objData[index]._isChecked = true
        }
      }
    },
    onSearch () {
      this.selectArr = new Set()
      this.selectArrSize = 0
      this.pageNum = 1
      this.getYardsList()
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getYardsList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getYardsList()
    },
    getYardsList () {
      let params = {
        goodsType: 1,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      if (this.type) params.childTypeName = this.typeName
      if (this.searchValue) params.goodsName = this.searchValue
      if (this.startDate) params.startTime = this.startDate
      if (this.endDate) params.endTime = this.endDate
      getYardsList(params).then(res => {
        this.totalPage = res.data.total
        this.data = res.data.list.map(item => {
          item.mainIngredient = item.extra.mainIngredient
          item.entName = item.holderList.length > 0 ? item.holderList[0].entName : ''
          return item
        })
        this.$nextTick(() => {
          this.setChecked();
        })
      })
    },
    // 下载数据包
    downloadYardsData (row) {
      // http://image.test.laodao.so/file/tradeapp/qrcode/trace/e2638f90-c7f5-3794-9322-4c43c9ecae98.txt
      let dowloadUrl = dowloadFile(row.dataPackageUrl)
      // 创建a标签
      const link = document.createElement('a')
      // href链接
      link.setAttribute('target', '_blank')
      link.setAttribute('href', dowloadUrl)
      // 自执行点击事件
      link.click()
    },
    // 点击详情
    onClickDetail (row) {
      this.detailVisible = true
      this.detailRuleId = row.id
    },
    // 点击删除记录
    onClickDelete (row) {
      this.onDelete([row.id])
    },
    // 批量删除记录
    batchDelete () {
      this.onDelete([...this.selectArr])
    },
    // 删除记录
    onDelete (list) {
      this.$Modal.confirm({
        title: '删除记录',
        content: '<p>是否删除记录？</p>',
        onOk: () => {
          let params = {
            idList: list.join()
          }
          deleteYards(params).then(res => {
            if (res.code === 0) {
              this.$Message.info('删除成功')
              this.selectArr = new Set()
              this.selectArrSize = 0
              this.getYardsList()
            } else {
              if (res.msg) this.$Message.info(res.msg)
            }
          })
        }
      })
    },
  },
}
</script>

<style lang='scss' scoped>
.main {
  height: 100%;
  background-color: #f9f9ff;
  .title {
    font-size: 22px;
    line-height: 66px;
    text-align: center;
    background-color: #fff;
    border-bottom: 1px solid #d3d1e7;
  }
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    .topbar-btn {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      color: #666;
      &:hover {
        color: #0652DD;
      }
    }
    ::v-deep {
      .ivu-select-selection,
      .ivu-input {
        background-color: transparent;
        border-color: #DCDFE6;
        border-radius: 18px;
      }
      .ivu-select-selection span {
        padding-left: 12px;
      }
      .ivu-input {
        padding: 4px 12px;
      }
    }
  }
  .list {
    padding: 0 20px 20px;
    .table {
      padding: 20px;
      background-color: #fff;
    }
    ::v-deep {
      .ivu-table-cell {
        padding: 0 8px;
      }
    }
  }
}
::v-deep .ivu-select-dropdown-list {
  max-height: 300px;
}
</style>
